<template>
    <div>
        <headingRow :text="label" size="17px" />
        <div class="row">
            <div class="col-md-12">
                <textarea
                    id="additionalInfo"
                    v-model="additionalInfo"
                    rows="4"
                    class="form-control"
                    :placeholder="placeholder"
                    style="resize: none; margin-top: 0.5rem"
                    maxlength="4000" />
                <p class="error-text placeholder"></p>
            </div>
        </div>
    </div>
</template>
<script>
import headingRow from "../text/HeadingRow.vue"

export default {
    name: "AdditionalInfoSection",
    components: { headingRow },
    computed: {
        label: {
            get() {
                return this.recipe.design.additionalInfoLabel
                    ? this.recipe.design.additionalInfoLabel
                    : "Additional Information"
            },
        },
        placeholder: {
            get() {
                return this.recipe.design.additionalInfoPlaceholder
            },
        },
        additionalInfo: {
            get() {
                return this.$store.state.transaction.ExtendedData["COMMENTS"]
            },
            set(value) {
                this.$store.commit("updateTransaction", {
                    propLocation: ["ExtendedData", "COMMENTS"],
                    value: value,
                })
            },
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
    },
}
</script>
<style scoped>
input::placeholder {
    color: lightgray;
}
</style>
