<template>
    <div class="row">
        <div class="col-md-8">
            <label for="station">{{ ddrpQuestion }}</label>
            <span v-if="searchingForExtraStations" id="spinnySpan">
                <clip-loader
                    :loading="true"
                    :color="recipe.design.theme.accentColor"
                    size="17px"
                    style="display: inline-block; padding-left: 10px">
                </clip-loader>
            </span>

            <select id="station" v-model="station" class="form-control">
                <option
                    v-for="s in stationList"
                    :key="s.label"
                    :value="s.frequency">
                    {{ s.label }}
                </option>
            </select>
            <p
                v-if="$v.station.$dirty && $v.station.$invalid"
                class="error-text text-right">
                {{ text.validation.fieldRequired }}
            </p>
            <p v-else class="error-text placeholder"></p>
        </div>
    </div>
</template>
<script>
import clipLoader from "vue-spinner/src/ClipLoader.vue"

export default {
    name: "DdrpStationSection",
    components: { clipLoader },
    data: function () {
        return {
            defaultStationList: [
                {
                    ddrpACall: null,
                    stationName: null,
                    frequency: "Web Streaming",
                    label: "Web Streaming",
                },
                {
                    ddrpACall: null,
                    stationName: null,
                    frequency: "Podcast",
                    label: "Podcast",
                },
                {
                    ddrpACall: null,
                    stationName: null,
                    frequency: "Moody Radio App",
                    label: "Moody Radio App",
                },
                {
                    ddrpACall: null,
                    stationName: null,
                    frequency: "Other",
                    label: "Other (None of the above)",
                },
            ],
            extraStations: [],
            searchingForExtraStations: false,
            station: "",
        }
    },
    computed: {
        ddrpQuestion: {
            get() {
                if (this.language == "spanish") {
                    if (this.designationDescription) {
                        return (
                            this.text.ddrp.ddrpQuestion +
                            this.designationDescription +
                            "?"
                        )
                    } else {
                        return this.text.ddrp.ddrpQuestionDefault
                    }
                } else {
                    return (
                        this.text.ddrp.ddrpQuestion +
                        this.designationDescription +
                        "?"
                    )
                }
            },
        },
        designationDescription: {
            get() {
                if (this.recipe && this.recipe.features.ddrpStationSelect) {
                    return this.recipe.features.ddrpStationSelect
                } else {
                    return "us"
                }
            },
        },
        isInternational: {
            get() {
                return this.$store.state.transaction.Donor.Country == "US" ||
                    this.$store.state.transaction.Donor.Country == "CA"
                    ? false
                    : true
            },
        },
        recipe: {
            get() {
                return this.$store.state.recipe
            },
        },
        searchingZip: {
            get() {
                return this.$store.state.searchingZip
            },
        },
        stationList: {
            get() {
                let newList = this.extraStations.concat(this.defaultStationList)

                // add a default "blank" item only if nothing has been selected yet
                // note this option is invalid for submit because the validation has a minlength of 1
                if (!this.station)
                    newList.unshift({
                        ddrpACall: null,
                        stationName: null,
                        frequency: "",
                        label: "",
                    })

                return newList
            },
        },
        text: {
            get() {
                return this.$store.state.text[this.$store.getters.formLanguage]
            },
        },
        transaction: {
            get() {
                return this.$store.state.transaction
            },
        },
        $v: {
            get() {
                return this.$store.getters.$v
            },
        },
        zip: {
            get() {
                return this.transaction.Donor.Postal
            },
        },
    },
    watch: {
        station: function (value) {
            let stationObj = this.stationList.find((x) => x.frequency == value)
            let groupCall =
                stationObj.stationName && stationObj.frequency
                    ? stationObj.stationName
                          .replace(stationObj.frequency, "")
                          .trim()
                    : stationObj.stationName
            this.$store.commit("updateDdrpStation", {
                ...stationObj,
                groupCall,
            })
        },
        zip: async function (value) {
            if (
                ((value.length == 5 && !isNaN(value)) || value.length == 7) &&
                !this.isInternational &&
                this.recipe.codes.designation
            ) {
                this.searchingForExtraStations = false
                try {
                    var extraStations = await this.$store.dispatch(
                        "getDdrpStationsForZip",
                        {
                            zip: value,
                            designation: this.recipe.codes.designation,
                        },
                    )
                    this.searchingForExtraStations = false
                    this.extraStations = extraStations.data.map((s) => ({
                        ...s,
                        label: s.description
                            ? s.description
                            : s.frequency,
                    }))
                } catch (error) {
                    this.searchingForExtraStations = false
                    console.error(error)
                }
            }
        },
    },
}
</script>
<style scoped>
#spinnySpan {
    position: relative;
    top: 3px;
}

:deep(.fade-enter-active),
:deep(.fade-leave-active) {
    transition: opacity 0.5s;
}

:deep(.fade-enter), :deep(.fade-leave-to) /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

:deep(.v-select) {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
}

:deep(.vs__selected-options),
:deep(.vs__actions) {
    margin-top: 0.2rem;
}

:deep(.vs__dropdown-toggle) {
    padding-bottom: 0.3rem;
}

/*.vs--open {
        height:38.5px;
    }*/

input[type="checkbox"] {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    position: relative;
    top: -4px;
    right: 4px;
    overflow: hidden;
}

input::placeholder {
    color: lightgray;
}

input:disabled {
    background: none;
    border-color: darkgray;
    cursor: not-allowed;
}

.text-muted {
    font-size: 12px;
}
</style>
