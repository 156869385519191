<template>
    <div class="container parent-div" style="height: 100%">
        <div class="row justify-content-center" style="height: 100%">
            <div
                id="center-panel"
                class="col-lg-9 col-lg-10 col-md-11"
                :style="cssVars">
                <div class="row justify-content-center">
                    <div class="col-xl-9 col-md-10">
                        <h1 class="sorry">{{ text.errorPage.title }}</h1>
                        <errorSection />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import errorSection from "../sections/Error.vue"
import Vue from "vue"

export default {
    name: "ErrorPage",
    components: { errorSection },
    computed: {
        columnBackground: {
            get() {
                return this.design.theme.columnBackground
            },
        },
        cssVars: {
            get() {
                return {
                    "--col-background": this.columnBackground,
                }
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
    },
    created: function () {
        if (Vue.rollbar)
            Vue.rollbar.warning(
                this.$router.currentRoute.params.reason
                    ? this.$router.currentRoute.params.reason
                    : "Public giving form redirected to error page",
            )
    },
}
</script>
<style>
html {
    overflow-y: scroll;
}

#center-panel {
    background-color: var(--col-background);
}

.grecaptcha-badge {
    visibility: hidden;
}

.sorry {
    margin-top: 20px;
    margin-bottom: 15px;
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */
    #center-panel {
        background-color: ghostwhite;
    }
}
</style>
