﻿<template>
    <div class="row justify-content-center" style="margin-bottom: 1em">
        <span :style="{ color: accentColor, fontWeight, fontSize }">{{
            text
        }}</span>
    </div>
</template>
<script>
export default {
    name: "HeadingRow",
    props: {
        text: {
            type: String,
            default: function () {
                return ""
            },
        },
        colorOverride: {
            type: String,
            default: function () {
                return undefined
            },
        },
        size: {
            type: String,
            default: function () {
                return "16px"
            },
        },
        weight: {
            type: String,
            default: function () {
                return "600"
            },
        },
    },
    computed: {
        accentColor: {
            get() {
                return this.design ? this.design.accentColor : "black"
            },
        },
        color: {
            get() {
                if (this.colorOverride) return this.colorOverride
                return this.accentColor
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        fontSize: {
            get() {
                return this.size ? this.size : "16px"
            },
        },
        fontWeight: {
            get() {
                return this.weight ? this.weight : "600"
            },
        },
        //recipe: {
        //    get() {
        //        return this.$store.state.recipe;
        //    }
        //}
    },
}
</script>
<style scoped></style>
