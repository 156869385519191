<template>
    <div>
        <!-- eslint-disable vue/no-v-html -->
        <div
            id="heading"
            class="row justify-content-center"
            v-html="heading"></div>
        <!-- eslint-enable vue/no-v-html -->

        <!--horizontal layout-->
        <div v-if="layout == 'horizontal'" class="row">
            <div class="col-md-4">
                <div class="img-div justify-content-center" :style="cssVars">
                    <img
                        id="graphic"
                        :title="premiumContent.title"
                        :src="premiumContent.graphic"
                        :alt="premiumContent.title" />
                </div>
            </div>
            <div id="contentColumn" class="col-md-8" :style="cssVars">
                <!-- eslint-disable vue/no-v-html -->
                <span id="description" v-html="description"></span>
                <span
                    v-if="callToAction"
                    id="callToAction"
                    v-html="callToAction"></span>
                <!-- eslint-enable vue/no-v-html -->
            </div>
        </div>
        <div v-if="layout == 'horizontal'" class="row mt-1 d-sm-none"></div>
        <div
            v-if="layout == 'horizontal'"
            class="row mt-3 d-none d-sm-block"></div>

        <!--vertical layout-->
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <div
                        class="img-div justify-content-center"
                        :style="cssVars">
                        <img
                            id="graphic"
                            :title="premiumContent.title"
                            :src="premiumContent.graphic"
                            :alt="premiumContent.title" />
                    </div>
                </div>
            </div>
            <div id="contentRow" class="row" :style="cssVars">
                <div class="col-md-12">
                    <!-- eslint-disable vue/no-v-html -->
                    <span id="description" v-html="description"></span>
                    <span
                        v-if="callToAction"
                        id="callToAction"
                        v-html="callToAction"></span>
                    <!-- eslint-enable vue/no-v-html -->
                </div>
            </div>
        </div>

        <div class="row" :style="cssVars">
            <div class="col-md-12">
                <div class="justify-content-center">
                    <div>
                        <label
                            for="premiumCheck"
                            style="margin-bottom: 20px; margin-left: 7px">
                            <!-- eslint-disable vue/no-v-html -->
                            <input
                                id="premiumCheck"
                                v-model="premium"
                                type="checkbox"
                                :disabled="premiumUnavailable"
                                class="mr-2" /><span
                                v-html="checkboxText"></span>
                            <!-- eslint-enable vue/no-v-html -->
                            <div
                                v-if="
                                    premiumLocation == 'bottom' &&
                                    premiumUnavailable
                                "
                                class="error-text">
                                {{ text.validation.intlPremiumUnavailable }}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PremiumSection",
    computed: {
        heading: {
            get() {
                return this.premiumLayout && this.premiumLayout.heading
                    ? this.$store.getters.compileMarkdown(
                          this.premiumLayout.heading,
                      )
                    : null
            },
        },
        description: {
            get() {
                return this.premiumContent && this.premiumContent.description
                    ? this.$store.getters.compileMarkdown(
                          this.premiumContent.description,
                      )
                    : null
            },
        },
        callToAction: {
            get() {
                return this.premiumContent && this.premiumContent.callToAction
                    ? this.$store.getters.compileMarkdown(
                          this.premiumContent.callToAction,
                      )
                    : null
            },
        },
        checkboxText: {
            get() {
                return this.customCheckVerbiage == null
                    ? this.text.premium.defaultCheckbox +
                          " <em>" +
                          this.premiumContent.title +
                          "</em>"
                    : this.customCheckVerbiage
            },
        },
        customCheckVerbiage: {
            get() {
                return this.premiumLayout && this.premiumLayout.checkboxText
                    ? this.$store.getters
                          .compileMarkdown(this.premiumLayout.checkboxText)
                          .replace("<p>", "")
                          .replace("</p>", "")
                    : null
            },
        },
        cssVars: {
            get() {
                return {
                    "--img-height": this.premiumLayout.graphicHeight
                        ? this.premiumLayout.graphicHeight + "px"
                        : "auto",
                    "--img-top-margin": this.premiumLayout.graphicTopMargin
                        ? this.premiumLayout.graphicTopMargin + "px"
                        : "auto",
                    "--text-top-margin": this.premiumLayout.textTopMargin
                        ? this.premiumLayout.textTopMargin + "px"
                        : "0",
                    "--text-alignment": this.premiumLayout.textAlignment
                        ? this.premiumLayout.textAlignment
                        : "left",
                }
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        layout: {
            get() {
                return this.premiumLayout && this.premiumLayout.layout
                    ? this.premiumLayout.layout
                    : "horizontal"
            },
        },
        premium: {
            get() {
                return this.$store.state.isPremiumSelected
            },
            set(value) {
                // While the premium code is available as an integer in the root of the premium object, the content block contains the 0-padded string version
                this.$store.commit("updatePremiumSelection", {
                    value,
                    code: this.premiumContent.itemCd,
                })
            },
        },
        premiumBundle: {
            get() {
                return this.$store.getters.premium
            },
        },
        premiumLocation: {
            get() {
                return this.premiumBundle
                    ? this.premiumBundle.layout.location
                    : "afterBlurb"
            },
        },
        premiumUnavailable: {
            get() {
                return this.$store.state.premiumUnavailable
            },
        },
        premiumContent: {
            get() {
                return this.premiumBundle?.content
            },
        },
        premiumLayout: {
            get() {
                return this.premiumBundle?.layout
            },
        },
        text: {
            get() {
                return this.$store.state.text
                    ? this.$store.state.text[this.$store.getters.formLanguage]
                    : null
            },
        },
    },
    watch: {
        premiumUnavailable: function (value) {
            if (value && this.premium) this.premium = false
        },
    },
    created() {
        if (this.premiumLayout.selectedByDefault) this.premium = true
    },
}
</script>
<style scoped>
.check-div {
    margin-bottom: 20px;
}

.img-div {
    max-height: var(--img-height);
    text-align: center;
}

@media (min-width: 768px) {
    #contentColumn {
        margin-top: var(--text-top-margin);
    }

    .img-div {
        height: var(--img-height);
        margin-top: var(--img-top-margin);
    }
}

@media (max-width: 767px) {
    #heading {
        margin-left: 2px;
    }

    .img-div {
        margin-bottom: 20px;
    }
}

#contentRow {
    text-align: var(--text-alignment);
    margin-top: var(--text-top-margin);
}

#graphic {
    max-height: var(--img-height);
    max-width: 100%;
}

input[type="checkbox"] {
    display: inline-block;
    margin-right: 15px;
    transform: scale(1.3);
}

label {
    margin-bottom: 0;
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ CSS fallback styles go here */
    .check-div {
        margin-top: 30px;
    }

    .img-div {
        height: auto;
    }
}
</style>
