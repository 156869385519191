﻿<template>
    <component :is="template" v-if="template" />
</template>
<script>
import defaultConfirmationTemplate from "../templates/DefaultConfirmationTemplate.vue"

export default {
    name: "ConfirmationPage",
    components: { defaultConfirmationTemplate },
    computed: {
        template: {
            get() {
                return "defaultConfirmationTemplate"
            },
        },
    },
    beforeDestroy: function () {
        // Discard any remaining user data
        this.$store.commit("clearReceipt")
    },
}
</script>
<style>
/*rules for all confirmation page templates*/
@media print {
    .no-print {
        display: none;
    }

    /*this doesn't work if the style block is scoped*/
    .grecaptcha-badge {
        visibility: hidden;
    }
}
</style>
