<template>
    <div style="text-align: center">
        <a
            :style="{ color: accentColor }"
            style="font-size: 14px"
            @click="toggleAutogiftHelp()"
            >{{ text.autogiftHelp.autogiftHeader }}</a
        >
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="showAutogiftHelp"
            id="autogiftHelp"
            style="text-align: left"
            v-html="text.autogiftHelp.autogiftContent"></div>
        <!-- eslint-enable vue/no-v-html -->
    </div>
</template>
<script>
export default {
    name: "AutogiftHelpTextSection",
    data: function () {
        return {
            showAutogiftHelp: false,
        }
    },
    computed: {
        accentColor: {
            get() {
                return this.design ? this.design.theme.accentColor : null
            },
        },
        design: {
            get() {
                return this.$store.getters.design
            },
        },
        text: {
            get() {
                return this.$store.state.text[this.$store.getters.formLanguage]
            },
        },
    },
    methods: {
        toggleAutogiftHelp: function () {
            this.showAutogiftHelp = !this.showAutogiftHelp
        },
    },
}
</script>
<style scoped>
a:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>
